import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import styles from './styles';
import Screen from '../../common/Screen';
import CommonPropTypes from '../../common/propTypes';
import { getPathname } from '../../navigation/selectors';
import ErrorList from '../../common/errors/ErrorList';
import Runner from '../Runner';
import Header from '../Header';
import SubHeader from '../SubHeader';
import PrivacyNotice from '../PrivacyNotice';
import Footer from '../Footer';
import { useOktaAuth } from '@okta/okta-react';
import { storeAuthState } from '../../auth/actions';
import { noop } from '../../common/utils';
import { initialAuthState } from '../../auth/reducer';
import { getUserTaId, getUserName } from '../../db/selectors';
import oktaConfig from '../../auth/oktaConfig';
import { ROUTE_LOGIN } from '../../routeConstants';
import Countdown from 'react-countdown';
import IdleTimer from 'react-idle-timer';
import AlertBanner from '../../common/AlertBanner';
import Button from '../../common/Button';
import {
    COUNTDOWN_TIME,
    IDLE_BANNER_TITLE,
    IDLE_BANNER_CONTENT_TEXT,
    TIMEOUT_TIME,
} from './constants';

function MainLayout({
    classes,
    className,
    component: Component,
    children,
    errors,
    storeAuthState,
    name,
    taId,
    pathname,
}) {
    const { authState, authService } = useOktaAuth();
    const loggedIn = authState.isAuthenticated;
    const { isAuthenticated } = authState;
    const [showIdleBanner, setShowIdleBanner] = useState();
    const idleTimerRef = useRef(null);
    const sessionTimeoutRef = useRef(null);
    const onIdle = () => {
        setShowIdleBanner(true);
        sessionTimeoutRef.current = setTimeout(logOut, 300 * 1000);
    };
    const logOut = () => {
        authService.logout(oktaConfig.logoutUri);
        setShowIdleBanner(false);
        clearTimeout(sessionTimeoutRef.current);
        localStorage.removeItem('loginNotified');
    };

    const renderer = ({ minutes, seconds }) => {
        const minutesDisplay = minutes < 10 ? `0${minutes}` : `${minutes}`;
        const secondsDisplay = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return <span>{`${minutesDisplay}:${secondsDisplay}`}</span>;
    };

    useEffect(() => {
        if (!isAuthenticated) {
            // When user isn't authenticated, forget any user info
            storeAuthState(initialAuthState);
        } else {
            const { idToken, accessToken } = authState;
            if (idToken && accessToken) {
                authService
                    .getUser()
                    .then((userInfo) => {
                        storeAuthState({
                            idToken,
                            accessToken,
                            userInfo,
                        });
                    })
                    .catch(() => {
                        storeAuthState({
                            idToken: null,
                            accessToken: null,
                            userInfo: { name: '', given_name: '' },
                        });
                    });
            }
        }
    }, [authState, authService, storeAuthState, isAuthenticated]); // Update if authState changes

    const renderHeader = useCallback(() => {
        return (
            <Grid
                item
                component={Header}
                onLogout={() => {
                    localStorage.removeItem('loginNotified');
                    authService.logout(oktaConfig.logoutUri)
                }}
                loggedIn={loggedIn}
                name={name}
                taId={taId}
            />
        );
    }, [name, taId, loggedIn, authService]);

    return (
        <>
            {
                // NOTE: DO NOT APPLY Grid[spacing] TO ANY OF THESE BLOCKS!
            }
            <Grid
                container
                direction="column"
                className={classes.root}
                wrap="nowrap"
            >
                <Grid item justify="center" className={classes.runner}>
                    <Grid container item xs={12}>
                        <Grid item component={Runner} />
                    </Grid>
                </Grid>
                <Grid
                    item
                    justify="center"
                    alignItems="center"
                    className={classes.header}
                >
                    <Grid container item xs={12}>
                        {renderHeader()}
                        {loggedIn && (
                            <IdleTimer
                                ref={idleTimerRef}
                                timeout={TIMEOUT_TIME}
                                onIdle={onIdle}
                            />
                        )}
                    </Grid>
                </Grid>
                {loggedIn && (
                    <Grid item className={classes.subHeader}>
                        <Container item alignItems="flex-start">
                            <Grid container item xs={12}>
                                <Grid item component={SubHeader} />
                            </Grid>
                        </Container>
                    </Grid>
                )}
                <Grid item justify="center">
                    <Container item alignItems="flex-start">
                        <Grid container item xs={12}>
                            {errors ? <ErrorList errors={errors} /> : null}
                        </Grid>
                    </Container>
                </Grid>
                <Grid
                    container
                    item
                    direction="row"
                    justify="center"
                    className={clsx(
                        { [classes.appContent]: pathname !== ROUTE_LOGIN },
                        {
                            [classes.appContentLoggedOut]:
                                pathname === ROUTE_LOGIN,
                        },
                    )}
                >
                    <Grid item>
                        <Container>
                            {loggedIn && showIdleBanner && (
                                <Grid
                                    container
                                    alignItems="center"
                                    justify="center"
                                    item
                                    className={classes.idleBannerContainer}
                                >
                                    <AlertBanner
                                        title={IDLE_BANNER_TITLE}
                                        contentInline={false}
                                        textLine2={
                                            <Grid item component={Typography}>
                                                {IDLE_BANNER_CONTENT_TEXT}
                                                <Countdown
                                                    date={
                                                        Date.now() +
                                                        COUNTDOWN_TIME
                                                    }
                                                    renderer={renderer}
                                                    onComplete={logOut}
                                                />
                                            </Grid>
                                        }
                                        closeButton={true}
                                        CloseButtonProps={{
                                            onClick: () =>
                                                setShowIdleBanner(false),
                                        }}
                                        ActionButton1={
                                            <Button
                                                size="medium"
                                                color="secondary"
                                                className={classes.idleButton}
                                                onClick={() =>
                                                    setShowIdleBanner(false)
                                                }
                                            >
                                                Stay Signed In
                                            </Button>
                                        }
                                        ActionButton2={
                                            <Button
                                                size="medium"
                                                className={classes.idleButton}
                                                onClick={logOut}
                                            >
                                                Sign Out
                                            </Button>
                                        }
                                    />
                                </Grid>
                            )}
                            <Grid
                                container
                                item
                                direction="column"
                                alignItems="stretch"
                            >
                                {children}
                            </Grid>
                        </Container>
                    </Grid>
                </Grid>
                {pathname === ROUTE_LOGIN && (
                    <Grid
                        item
                        justify="center"
                        className={classes.privacyNotice}
                    >
                        <Container item>
                            <Grid container item alignItems="flex-end" xs={12}>
                                <Grid item component={PrivacyNotice} />
                            </Grid>
                        </Container>
                    </Grid>
                )}
                <Grid container item direction="row" justify="flex-end">
                    <Grid item className={classes.version}>
                        <Typography color="secondary">
                            v.release/{process.env.REACT_APP_VERSION}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    item
                    alignItems="center"
                    justify="center"
                    className={classes.footer}
                >
                    <Grid item component={Footer} />
                </Grid>
            </Grid>
        </>
    );
}

MainLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    component: CommonPropTypes.component,
    children: PropTypes.node,
    errors: CommonPropTypes.errors,
    storeAuthState: PropTypes.func,
    name: PropTypes.string,
    taId: PropTypes.string,
    pathname: PropTypes.string.isRequired,
};

MainLayout.defaultProps = {
    component: Screen,
    className: null,
    children: null,
    errors: null,
    storeAuthState: noop,
    name: '',
    taId: '',
};

const StyledMainLayout = withStyles(styles)(MainLayout);

export default StyledMainLayout;

const mapStateToProps = (state) => ({
    pathname: getPathname(state),
    name: getUserName(state),
    taId: getUserTaId(state),
});

const mapDispatchToProps = (dispatch) => ({
    storeAuthState: (authState) => dispatch(storeAuthState(authState)),
});

export const ConnectedMainLayout = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StyledMainLayout);
