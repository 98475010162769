import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import {
    Security,
    useOktaAuth,
    LoginCallback,
    SecureRoute,
} from '@okta/okta-react';
import {
    ROUTE_REGISTER,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_RESULTS
} from './routeConstants';
import { ConnectedTokensForm } from './screens/TokensForm';
import { ConnectedTokensResultsScreen } from './screens/TokensResultsScreen';
import { ConnectedLoginScreen } from './screens/LoginScreen';
import { ConnectedRegisterScreen } from './screens/RegisterScreen';
import { connect } from 'react-redux';
import oktaConfig from './auth/oktaConfig';
import { getUser, getUserTaStatus } from './db/selectors';


const Routes = (props) => {
    const { user, taStatus } = props;
    const { authState, authService } = useOktaAuth();
    const loggedIn = authState.isAuthenticated;
    const [userPage, setUserPage] = useState(null);

    let loginNotified = false;

    const dispatch = useDispatch()

    const notifyLoggedIn = () => {

        dispatch({type:"AUDIT_LOGIN", value: 1})

        localStorage.setItem('loginNotified', true);
    }

    useEffect(() => {

        if(!localStorage.getItem('loginNotified')) {
            localStorage.setItem('loginNotified', false);
        }
        // eslint-disable-next-line
        loginNotified = localStorage.getItem('loginNotified'); 

        if (taStatus === 'inactive') {
            authService.logout(oktaConfig.logoutUri);
        } else {
            if (user) {
                if (user.status === 'AUTHORIZED') {
                    if(loginNotified === "false") {
                        notifyLoggedIn();
                    } 
                    setUserPage('home');
                } else {
                    setUserPage('register');
                }
            }
        }
    }, [user, taStatus, authService]);

    return loggedIn ? (
        taStatus === 'active' && userPage === 'home' ? (
            /* AUTHENTICATED ROUTES */
            <Switch>
                <SecureRoute
                    path={ROUTE_HOME}
                    component={ConnectedTokensForm}
                />
                <SecureRoute
                    path={ROUTE_RESULTS}
                    component={ConnectedTokensResultsScreen}
                />
                <Redirect to={ROUTE_HOME} />
            </Switch>
        ) : userPage === 'register' ? (
            /* Account Registration Route */
            <Switch>
                <SecureRoute
                    path={ROUTE_REGISTER}
                    component={ConnectedRegisterScreen}
                />
                <Redirect to={ROUTE_REGISTER} />
            </Switch>
        ) : (
            /* UNAUTHENTICATED ROUTES */
            <Switch>
                <Route path={`${ROUTE_LOGIN}/callback`} component={LoginCallback} />
                <Route path={ROUTE_LOGIN} component={ConnectedLoginScreen} exact />
                <Redirect exact from="/" to={ROUTE_LOGIN} />
            </Switch>
        )
    ) : ( //loggedIn Check
        /* UNAUTHENTICATED ROUTES */
        <Switch>
            <Route path={`${ROUTE_LOGIN}/callback`} component={LoginCallback} />
            <Route path={ROUTE_LOGIN} component={ConnectedLoginScreen} exact />
            <Redirect exact from="/" to={ROUTE_LOGIN} />
        </Switch>
    );
};

function Router({ history, user, taStatus }) {
    return (
        <ConnectedRouter history={history}>
            <Security {...oktaConfig}>
                <Routes user={user} taStatus={taStatus} />
            </Security>
        </ConnectedRouter>
    );
}

Router.propTypes = {
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    taStatus: PropTypes.string
};

Router.defaultProps = {
    user: null,
    taStatus: null
};

export const mapStateToProps = (state) => ({
    user: getUser(state),
    taStatus: getUserTaStatus(state)
});

export default connect(mapStateToProps, null)(Router);
