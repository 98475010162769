import { takeLatest, call } from 'redux-saga/effects';
import { fetchWithAuth } from '../../store/api/sagas';

export function* auditLogin() {
    yield call(
            fetchWithAuth,`extLoginSuccess`,{method: 'GET'}
        );
}

export default function* auditLoginSaga() {
    yield takeLatest('AUDIT_LOGIN', auditLogin)
}