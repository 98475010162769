import { all, call } from 'redux-saga/effects';
import initSaga from '../init/saga';
import tokensForm from '../screens/TokensForm/saga';
import auth from '../auth/saga';
import db from '../db/saga';
import auditLoginSaga from './audit/saga';

export default function* rootSaga() {
    yield all([call(initSaga), call(tokensForm), call(auth), call(db), call(auditLoginSaga)]);
}
